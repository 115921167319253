@import 'variables';

$modal-padding: 32px;
$font-size-medium: 14px;
$primary-color-orange: #ee5a2f;

body {

    .content {
        a {
            color: $darkblue;
            opacity: 0.7;

            &:hover {
                color: $darkblue;
                opacity: 1;
            }
        }
    }
}

.breadcrumb {
    background-color: transparent;
    padding-left: 32px;
}

label {
  margin-bottom: 0;
}

/* Buttons */
.btn {

    font-size: $font-size-medium;

    &:focus {
        box-shadow: none;
    }

    &.btn-default {
        height: 40px;
        min-width: 120px;
        border-radius: 20px;
        border: solid 1px $secondary-color-light;
        color: $secondary-color;
        background-color: $light-grey;

        &:hover {
            background-color: $secondary-color-light;
        }
    }

    &.btn-primary {
        height: 40px;
        min-width: 120px;
        border-radius: 20px;
        border: solid 1px $primary-color-orange;
        color: $light-grey;
        background-color: $primary-color-orange;

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
            color: $light-grey;
            background-color: $primary-color-orange;
            border: solid 1px $primary-color-orange;
        }

        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        }

        &:hover {
            background-color: $light-grey;
            color: $primary-color-orange;
        }
    }
}

/* Modal */
.modal-dialog {
    .modal-content {

        border: none;
        background-color: $light-grey;
        border-radius: 8px;

        .modal-header {
            border-bottom: none;
            color: $primary-color;
            padding-top: $modal-padding;
            padding-left: $modal-padding;

            .modal-title {
                font-size: $font-size-large;
            }
        }

        .modal-body {
            padding-left: $modal-padding;
            color: $secondary-color;
            font-size: $font-size-medium;
            border-bottom: none;
        }

        .modal-footer {
            border-top: none;
        }
    }
}
