@import 'variables';

.labeled-checkbox {
  display: flex;
  align-items: center;

  label {
    padding-left: 8px;
    cursor: pointer;
  }
}

.checkbox-label {
  cursor: pointer;
}


/* The checkbox-container */
.checkbox-container {
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: solid 2px #cccccc;
  background-color: #ffffff;
}

/* On hover, transition in the border */
.checkbox-container:hover input:not(:disabled) ~ .checkmark {

  transition: border 500ms;
  border: solid 2px $primary-color;

  &.darkblue {
   transition: border 500ms;
   border: solid 2px $darkblue;
  }
}

.checkbox-container input:disabled ~ .checkmark {
  cursor: default;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: $primary-color;
  border: solid 2px $primary-color;

  &.darkblue {
    background-color: $darkblue;
    border: solid 2px $darkblue;
  }
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid 2px #ffffff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);

}
